<script>
export default {
  name: 'FileField',
  components: {
    Action: () => import('@/components/general/Action'),
    Icon: () => import('@/components/general/Icon'),
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  props: {
    label: {
      type: String,
      default: null,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    accept: {
      type: String,
      default: '*'
    },

    multiple: {
      type: Boolean,
      default: false
    },

    chooseDesign: {
      type: Number,
      default: 1
    },

    validation: {
      type: Object,
      default: function () {
        return {}
      }
    },

    hint: {
      type: String,
      default: null
    },

    dark: {
      type: Boolean,
      default: false
    },

    underDescription: {
      type: String,
      default: null
    },

    isEnabledDeleteButton: {
      type: Boolean,
      default: false
    },

    value: null
  },

  data () {
    return {
      isFocused: false,
      mutableValue: null
    }
  },

  computed: {
    hasValue () {
      return this.value && !this.isEmptyArray ? this.value : null
    },

    isEmptyArray () {
      return (typeof this.value === 'object' && this.value.length === 0)
    }
  },

  watch: {
    value () {
      if (this.value === null) {
        this.mutableValue = null
      }
    }
  },

  mounted () {
    if (this.value !== null && !this.isEmptyArray) {
      let fileName = this.value

      if (typeof this.value === 'object') {
        fileName = this.value[0].name ? this.value[0].name : this.value[0].asset.name
      }

      this.mutableValue = fileName
    }

    const labelSize = this.$refs.label.offsetWidth + 20

    this.$refs.wrapper.style.marginLeft = labelSize + 'px'

    if (this.$refs.details) {
      this.$refs.details.style.marginLeft = labelSize + 'px'
    }
  },

  methods: {
    updateValue (fileList) {
      if (fileList[0]) {
        typeof this.validation.$touch === 'function' && this.validation.$touch()
        this.mutableValue = fileList[0].name
        this.$emit('input', fileList)
      }
    },

    updateFocus () {
      this.isFocused = true
    },

    updateBlur () {
      this.isFocused = false
    },

    openFile () {
      this.$refs.label.click()
    },

    clear () {
      this.$emit('clear')
      this.$refs.input.value = null
    }
  }
}
</script>

<template>
  <div
    class="form-item form-file-field"
    :class="{ 'has-error': validation.$error, 'has-value': value !== null, 'has-focus': isFocused, 'is-disabled': disabled, 'theme-dark': dark }"
  >
    <p
      v-if="underDescription"
      class="form-input-subtext"
    >
      {{ underDescription }}
    </p>
    <div class="form-input-wrapper">
      <label
        v-if="label"
        ref="label"
        class="form-input-file-label"
        :for="'input' + _uid"
      >
        <span class="form-input-file-label-text">{{ label }}</span>
      </label>
      <div
        v-if="chooseDesign === 1"
        ref="wrapper"
        class="form-input-wrapper"
      >
        <input
          :id="'input' + _uid"
          ref="input"
          class="form-input-file"
          spellcheck="false"
          type="file"
          :multiple="multiple"
          :disabled="disabled"
          :readonly="readonly"
          :accept="accept"
          @change="updateValue($event.target.files)"
          @focus="updateFocus()"
          @blur="updateBlur()"
        >

        <div class="form-input-file-container">
          <input
            class="form-input"
            type="text"
            readonly
            :value="mutableValue"
            tabindex="-1"
            @click="openFile()"
          >

          <Action
            v-if="isEnabledDeleteButton && hasValue"
            icon="delete"
            icon-right
            flat
            type="button"
            @click="clear()"
          />
        </div>
      </div>
      <div
        v-if="chooseDesign === 2"
        ref="wrapper"
        class="form-input-wrapper file-2"
      >
        <input
          :id="'input' + _uid"
          ref="input"
          class="form-input-file-2"
          spellcheck="false"
          type="file"
          :multiple="multiple"
          :disabled="disabled"
          :readonly="readonly"
          :accept="accept"
          @change="updateValue($event.target.files)"
          @focus="updateFocus()"
          @blur="updateBlur()"
        >
        <div class="form-input-prepend">
          <Icon name="file-pdf" />
        </div>
        <input
          class="form-input"
          type="text"
          readonly
          :value="mutableValue"
          tabindex="-1"
          @click="openFile()"
        >
        <div class="form-input-append">
          <Icon name="download" />
        </div>
      </div>
      <div
        v-if="hint || validation.$error"
        ref="details"
        class="form-input-details"
      >
        <span
          v-if="hint && !validation.$error"
          class="form-input-hint"
        >{{ hint }}</span>
        <ValidationMessage :validation="validation" />
      </div>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/form.css"></style>

<style src="@/assets/styles/themes/default/file.css"></style>

<style scoped>
.form-input-file-container {
  display: flex;
}

.form-input-prepend {
  color: #5C2E91;
  z-index: 1;
}
</style>
